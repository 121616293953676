import React from "react";
import axios from "axios";
import Chip from "@mui/material/Chip";
import { Box, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import Header from "components/UI/Header/Header";
import RecapEmail from "../Intervention/Intervenant/Modal/RecapEmail/RecapEmail";
import DisplayAttachment from "../UI/DisplayAttachment/DisplayAttachment";
import jsPDF from "jspdf";
import SendIcon from "@mui/icons-material/Send";
import ProgressButton from "components/UI/LoadingButtonModal";
import JSZip from "jszip";

import { ButtonAttach, Attachment } from "./SendMailStyle";
import { useSnackbar } from 'notistack';

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 

const SendMail = (props) => {
  const [objectMail, setObjectMail] = React.useState(props.objetMail);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [isEmail, setIsEmail] = React.useState(false);
  const [destinataire, setDestinataire] = React.useState([]);
  const [cc, setCc] = React.useState([props.user.mail]);
  const [pending, setPending] = React.useState(false);
  const [mastoreMails, setMastoreMails] = React.useState([]);
  const [bodyMail, setBodyMail] = React.useState(props.Body);
  const [inputValue, setInputValue] = React.useState("");
  const [inputValueCc, setInputValueCc] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  
  const handleClickOpenEmail = () => {
    setOpenEmail(!openEmail);
  };

  React.useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("/legacy/getMails").then((response) => {
      setMastoreMails(response.data.mails);
    });
  }, []);

  const changeObjectMail = (event) => {
    setObjectMail(event.target.value);
  };

  const clearBody = () => {
    setBodyMail(
      `Bonjour,\n\n\n\nCordialement,\n${props.user.firstname} ${props.user.lastname}\n${props.user.mail}\nhttps://mastore.evictum.fr/intervention?IDMastore=${props.id_mastore}`
    );
  };

  const changeDestinataire = (event, newValue) => {
    setDestinataire(newValue);
    if (newValue.length > 0) {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
  };

  const handleQuillChange = (content) => {
    setBodyMail(content);
  };

  const formatBodyForQuill = (text) => {
    return text.replace(/\n/g, "<br />");
  };

  const handleMouseOut = (e) => {
    if (inputValue.length > 0) {
      setDestinataire([...destinataire, inputValue]);
      setInputValue("");
      setIsEmail(true);
    }
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleMouseOutCc = (e) => {
    if (inputValueCc.length > 0) {
      setCc([...cc, inputValueCc]);
      setInputValueCc("");
    }
  };

  const onInputChangeCc = (event, newInputValue) => {
    setInputValueCc(newInputValue);
  };

  const convertBase64ToPDF = (base64, fileName) => {
    const pdf = new jsPDF();
    pdf.addImage(base64, "JPEG", 0, 0);
    const pdfData = pdf.output("blob");
    return new File([pdfData], `${fileName}.pdf`, { type: "application/pdf" });
  };

  const sendDataTosendMail = async () => {
    setPending(true);
  
    try {
      let files = [];
      let names = [];
  
      // Check if there are attachments
      if (props.Attachment && props.Attachment.length > 0) {
        // Convert base64 files to PDF
        files = await Promise.all(
          props.Attachment.map(async (item) => {
            if (item.base64_file) {
              return convertBase64ToPDF(item.base64_file, item.name);
            } else if (item.url) {
              return item.file;
            }
            return item.file;
          })
        );
  
        let totalSize = files.reduce((acc, file) => acc + file.size, 0);
  
        // Check if files exceed the size limit
        if (totalSize > 15 * 1024 * 1024) {
          const zip = new JSZip();
          files.forEach((file, index) => {
            zip.file(props.Attachment[index].name, file);
          });
  
          const today = new Date();
          const dateStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
          const zipBlob = await zip.generateAsync({ type: "blob" });
          files = [new File([zipBlob], `pieces_jointes_mastore_${dateStr}.zip`, { type: "application/zip" })];
        }
  
        names = props.Attachment.map((item) => `${item.name}`);
      }
  
      // Envoi de l'e-mail
      await props.sendMail(destinataire, objectMail, bodyMail, files, names, cc);
  
      if (props.attribut === "Devis Client") {
        enqueueSnackbar("Veuillez passer le statut du devis à 'ENVOYÉ'", { variant: "warning" });
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'e-mail :", error);
      enqueueSnackbar("Échec de l'envoi de l'e-mail. Veuillez réessayer.", { variant: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <Box
      sx={{
        width: { xl: "65vw", md: "70vw", sm: "80vw", xs: "90vw" },
        maxHeight: "90vh",
      }}
    >
      <Header>Envoyer {props.attribut}</Header>
      {openEmail ? (
        <RecapEmail
          close={handleClickOpenEmail}
          titre=" demandes "
          resume={props.resume}
        />
      ) : null}
      <Stack sx={{ px: 3, maxHeight: "600px", overflow: "auto" }} spacing={1}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span
            style={{
              color: "#316690",
              width: "50.5%",
              fontWeight: "bold",
              fontSize: "14px",
              marginBottom: "8px",
            }}
          >
            Destinataires :
          </span>
          <span
            style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}
          >
            Cc :
          </span>
          <Autocomplete
            multiple
            size="small"
            value={destinataire}
            options={props.client}
            onChange={changeDestinataire}
            inputValue={inputValue}
            onInputChange={onInputChange}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={option} // Directly pass key here
                    size="small"
                    label={option}
                    {...tagProps}
                    disabled={option === props.user.mail}
                    sx={{ height: "100%" }}
                  />
                );
              })
            }
            style={{
              width: "49.5%",
              height: "10%",
              marginRight: "1%",
              background: "white",
            }}
            freeSolo
            onBlur={handleMouseOut}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Destinataires"
                size="small"
              />
            )}
          />
          <Autocomplete
            multiple
            value={cc}
            size="small"
            onChange={(event, newValue) => {
              setCc([
                props.user.mail,
                ...newValue.filter((option) => props.user.mail !== option),
              ]);
            }}
            options={mastoreMails}
            inputValue={inputValueCc}
            onInputChange={onInputChangeCc}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={option} // Directly pass key here
                    size="small"
                    label={option}
                    {...tagProps}
                    disabled={option === props.user.mail}
                    sx={{ height: "100%" }}
                  />
                );
              })
            }
            style={{ width: "49.5%", background: "white" }}
            freeSolo
            onBlur={handleMouseOutCc}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Cc"
                size="small"
              />
            )}
          />
        </div>
        <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
          Objet :
        </div>
        <TextField
          variant="outlined"
          value={objectMail}
          type="text"
          onChange={changeObjectMail.bind(this)}
          name="Objet"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
          Mail :
        </div>
        <ReactQuill
          theme="snow"
          value={formatBodyForQuill(bodyMail)}
          onChange={handleQuillChange}
          modules={{
            toolbar: [["bold", "italic", "underline", "strike"], ["link"], ["clean"]],
          }}
          style={{
            overflowY: "auto",
            borderRadius: "4px",
            background: "#fff",
            flexGrow: 1,

          }}
        />
        <Stack
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
            Pièces jointes :
          </div>
          <Attachment
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px", 
            }}
          >
            {props.Attachment
              ? props.Attachment.map((attachment, index) => (
                  <DisplayAttachment
                    i={index}
                    key={index}
                    file={attachment.file}
                    name={attachment.name}
                  />
                ))
              : null}
          </Attachment>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ pb: 2, pt: 1 }}
        >
          <ButtonAttach
            size="small"
            variant="outlined"
            onClick={props.OpenModal}
            startIcon={<AttachFileIcon />}
          >
            Joindre
          </ButtonAttach>
          
          <ButtonAttach size="small" variant="outlined" color="error" onClick={clearBody}>
            Effacer le corps du mail
          </ButtonAttach>
        </Stack>
      </Stack>
      <Stack
        sx={{ pb: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <ProgressButton
          name="Envoyer"
          update={isEmail}
          loading={pending}
          icon={<SendIcon />}
          clickHandler={sendDataTosendMail}
        />
      </Stack>
    </Box>
  );
};

export default SendMail;