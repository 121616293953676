import React from 'react';

// components
import OpenFile from '../OpenFile';
import OpenFileDevis from '../OpenFileDevis';

// MUI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ImageIcon from '@mui/icons-material/Image';

// styles
import { CustomIcon, CustomAttachment } from './DisplayAttachmentStyle';

const DisplayAttachment = (props) => {
    return (
        <Tooltip title={props.name} placement="top">
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <CustomAttachment>
                        {props.file && (
                            <OpenFileDevis
                                url={props.file}
                                icon={<ImageIcon />}
                                base64_file={props.file}
                                attachment={props.name}
                            />
                        )}
                </CustomAttachment>
                {
                    props.i !== 0 ?
                        <Button onClick={() => props.RemoveElem(props.i)} style={{ width: "25px", minWidth: "25px", padding: "5px" }}>
                            <CustomIcon />
                        </Button> : null
                }
            </div>
        </Tooltip>
    );
}

export default DisplayAttachment;
