import React, { useState, useEffect, useContext } from "react";
import { connect } from 'react-redux';
import { dateConfig } from 'config';
import moment from 'moment';
// MUI
import { styled } from '@mui/material/styles';
import {
    Popover,
    Typography,
    IconButton,
    Stack,
    Badge,
    Card,
    CardContent,
    Divider,
    Button,
    Box,
    Grid, 
    useTheme,
    alpha,
    Tooltip
} from '@mui/material';
// Icone
import { STATUS_MESSAGE } from 'config';
import MessageIcon from '@mui/icons-material/Message';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// service
import { getLastMessageByClient, putMessageRead } from "services";
// context
import ChatContext from "contexts/ChatContext";
// lodash
import { filter } from 'lodash';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 1,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function AllChats(props) {
    const currentDate = Date.now();
    const ctx = useContext(ChatContext);
    const [read, setRead] = useState(false);
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showClosedDiscussions, setShowClosedDiscussions] = useState(false);
    const [nbLastMessagesForBadge, setNbLastMessagesForBadge] = useState(0);
    const [nbLastMessagesNotAnswered, setNbLastMessagesNotAnswered] = useState(0);

    const theme = useTheme();
    
    let attached_to = '' + props.attached_to;

    const routeChange = (message) => {
        if (!message.read) {
            putMessageRead({ id_mastore: message.id_mastore, read: true });
        }
        setRead(!read);
        window.open('/intervention?IDMastore=' + message.id_mastore, '_blank');
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleClosedDiscussions = () => {
        setShowClosedDiscussions(!showClosedDiscussions);
    };

    useEffect(() => {
        Promise.all([
            getLastMessageByClient(attached_to)
        ]).then(([responseMessageByClient]) => {
            setMessage(responseMessageByClient.message);
            setNbLastMessagesForBadge(filter(responseMessageByClient.message, { 'send_by': 'client', 'read': false, 'closed': false }).length);
            setNbLastMessagesNotAnswered(filter(responseMessageByClient.message, { 'send_by': 'client', 'answered': false, 'closed': false }).length);
            setLoading(true);
        }).catch(err => {
            console.log(err);
        });
    }, [ctx.isNewMessage, read, attached_to, ctx.isOpenChat]);
    
    const HandleFormatDate = (date) => {
        let timeDif = currentDate - new Date(date).getTime();
        let minutes = Math.floor(timeDif / 60000);
        if (minutes < 1440) {
            return moment(date).format(dateConfig.format7);
        }
        if (minutes >= 1440 && minutes <= 525600) {
            return moment(date).format(dateConfig.format2);
        }
        if (minutes >= 525600) {
            return moment(date).format(dateConfig.format3);
        }
    };

    const handleStatusAvatar = (status) => {
        return <Typography variant='h7' color={STATUS_MESSAGE[status] || "#2065d1"}>{status}</Typography>;
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const noReadNoAnswer = message.filter(
        item => !item.read && !item.answered && item.send_by === "client" && !item.closed
    );
    
    const readNoAnswer = message.filter(
        item => item.send_by === "client" && item.read && !item.answered && !item.closed
    );

    const readAndAnswered = message.filter(
        item => item.send_by === "client" && item.read && item.answered && !item.closed
    );
    
    const isClosedMessages = message.filter(item => item.closed);

    const getCardBackground = (item) => {
        if (item.closed) {
            return '#B0B0B0';
        }
        if (!item.read && !item.answered) {
            return '#e3f2fd';
        }
        if (item.read && !item.answered) {
            return '#FFE0B2';
        }
        if (item.read && item.answered) {
            return '#C8E6C9';
        }
        return '#FFFFFF';
    };

    return (
        <div>
            <StyledBadge badgeContent={nbLastMessagesNotAnswered} color="error">
                <StyledBadge badgeContent={nbLastMessagesForBadge} color="secondary">
                    <IconButton onClick={handleClick}>
                        <MessageIcon />
                    </IconButton>
                </StyledBadge>
            </StyledBadge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock={true}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ 
                        width: showClosedDiscussions ? 650 : 450, 
                        padding: 2, 
                        maxHeight: 450, 
                        transition: 'width 0.3s ease',
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Grid container spacing={2} sx={{ transition: 'transform 0.3s ease', width: '100%' }}>
                        <Grid item xs={showClosedDiscussions ? 6 : 12} sx={{ transition: 'all 0.3s ease'}}>
                            <Box sx={{ marginBottom: 2, maxHeight: 350, overflowY: 'auto' }}>
                                {noReadNoAnswer.length > 0 ? (
                                    <>
                                        <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: 'bold', marginBottom: 1 }}>Nouveaux messages ({noReadNoAnswer.length})</Typography>
                                        {loading && noReadNoAnswer.map((item, index) => (
                                            <Tooltip key={index} title={item.closed ? "Discussion clôturée" : ""} placement="right" disableInteractive arrow>
                                                <Card variant="outlined" 
                                                sx={{
                                                    marginBottom: 1,
                                                    backgroundColor: getCardBackground(item),
                                                    cursor: 'pointer',
                                                }} onClick={() => routeChange(item)}>
                                                    <CardContent>
                                                        <Stack direction="row" justifyContent="space-between" paddingBottom={0.5}>
                                                            <Typography variant='h7'>{item.id_mastore}</Typography>
                                                            <Typography variant='h7'>{HandleFormatDate(item.CreatedAt)}</Typography>
                                                            {handleStatusAvatar(item.status)}
                                                        </Stack>
                                                        <Typography variant='h6' paddingBottom={0.5} paddingTop={0.5}>{item.enseigne} - {item.ville} N°[{item.numero_magasin}]</Typography>
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                            <Typography variant='body2' noWrap>{item.message}</Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Tooltip>
                                        ))}
                                        <Divider sx={{ marginY: 2 }} />
                                    </>
                                ) : (
                                    <CardContent>
                                        <Typography variant="h6" align="center" color={alpha(theme.palette.text.secondary, 0.30)} sx={{ py: 4 }}>
                                            Aucun nouveau message
                                        </Typography>
                                    </CardContent>
                                )}

                                {(readNoAnswer.length > 0 || readAndAnswered.length > 0) ? (
                                    <>
                                        <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                                            Messages en cours ({readNoAnswer.length + readAndAnswered.length})
                                        </Typography>
                                        {loading && [...readNoAnswer, ...readAndAnswered].map((item, index) => (
                                            <Tooltip key={index} title={item.closed ? "Discussion clôturée" : ""} placement="right" disableInteractive arrow>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        marginBottom: 1,
                                                        backgroundColor: getCardBackground(item),
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => routeChange(item)}
                                                >
                                                    <CardContent>
                                                        <Stack direction="row" justifyContent="space-between" paddingBottom={0.5}>
                                                            <Typography variant="h7">{item.id_mastore}</Typography>
                                                            <Typography variant="h7">{HandleFormatDate(item.CreatedAt)}</Typography>
                                                            {handleStatusAvatar(item.status)}
                                                        </Stack>
                                                        <Typography variant="h6" paddingBottom={0.5} paddingTop={0.5}>
                                                            {item.enseigne} - {item.ville} N°[{item.numero_magasin}]
                                                        </Typography>
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                            <Typography variant="body2" noWrap>{item.message}</Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Tooltip>
                                        ))}
                                    </>
                                ) : (
                                    <CardContent>
                                        <Typography variant="h6" align="center" color={alpha(theme.palette.text.secondary, 0.30)} sx={{ py: 4 }}>
                                            Aucun message en cours
                                        </Typography>
                                    </CardContent>
                                )}

                            </Box>
                        </Grid>
                        
                        <Grid item xs={6} sx={{ 
                                transition: 'opacity 0.3s ease, visibility 0.3s ease',
                                opacity: showClosedDiscussions ? 1 : 0,
                                visibility: showClosedDiscussions ? 'visible' : 'hidden',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                width: '50%',
                                height: '100%',
                                padding: 2,
                            }}
                        >
                            <Box sx={{ marginBottom: 2, maxHeight: 350, overflowY: 'auto' }}>
                                {isClosedMessages.length > 0 ? (
                                    <>
                                        <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: 'bold', marginBottom: 1 }}>Discussions clôturées ({isClosedMessages.length})</Typography>
                                        {loading && isClosedMessages.map((item, index) => (
                                            <Tooltip key={index} title={"Discussion clôturée"} placement="right" disableInteractive arrow>
                                                <Card variant="outlined" sx={{
                                                    opacity: 0.6,
                                                    marginBottom: 1,
                                                    cursor: 'pointer',
                                                    backgroundColor: getCardBackground(item),
                                                }} onClick={() => routeChange(item)}>
                                                    <CardContent>
                                                        <Stack direction="row" justifyContent="space-between" paddingBottom={0.5}>
                                                            <Typography variant="h7">{item.id_mastore}</Typography>
                                                            <Typography variant='h7'>{HandleFormatDate(item.CreatedAt)}</Typography>
                                                            {handleStatusAvatar(item.status)}
                                                        </Stack>
                                                        <Typography variant='h6' paddingBottom={0.5} paddingTop={0.5}>{item.enseigne} - {item.ville} N°[{item.numero_magasin}]</Typography>
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                            <Typography variant='body2' noWrap>{item.message}</Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Card>
                                            </Tooltip>
                                        ))}
                                    </>
                                ) : (
                                    <CardContent>
                                        <Typography variant="h6" align="center" color={alpha(theme.palette.text.secondary, 0.30)} sx={{ py: 4 }}>
                                            Aucune discussion clôturée
                                        </Typography>
                                    </CardContent>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Stack direction={"row"} sx={{ top: '0%', right: 0, position: 'absolute' }}>
                        <Button variant="text" color="inherit" onClick={toggleClosedDiscussions}>Clôturées
                        <IconButton onClick={toggleClosedDiscussions}>
                            <NavigateNextIcon sx={{ transition: 'transform 0.3s ease', transform: showClosedDiscussions ? 'rotate(180deg)' : 'none'}} />
                        </IconButton>
                        </Button>
                    </Stack>

                </Box>
            </Popover>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        attached_to: state.attached_to,
    };
};

export default connect(mapStateToProps, null)(AllChats);