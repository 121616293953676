import React from 'react';
import {
    Typography,
    Avatar,
    Stack,
} from '@mui/material';
import logo from 'assets/img/LogoMastore.png';

export const DisplayMessage = (props) => {
    return (
        <React.Fragment>
            <Stack spacing={0.8}>
                {props.message && props.message.length > 0 ?
                    props.message.map((item, index) => {
                        if (item.send_by === "mastore") {
                            return (
                                <React.Fragment key={`mastore-${index}`}>
                                    <Typography variant="caption" textAlign={"right"} sx={{ color: "#65676b"}}>{props.user.firstname} {props.user.lastname}</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                                        <Typography style={{ backgroundColor: "#0084FF", borderRadius: 10, padding: "6px 10px 6px 10px", color: "white" }} variant="body1">{item.message}</Typography>
                                        <Avatar sx={{ width: 24, height: 24, backgroundColor: "white" }}>
                                            <img src={logo} alt="logo" />
                                        </Avatar>
                                    </Stack>
                                </React.Fragment>
                            );
                        } else if (item.send_by === "client") {
                            return (
                                <React.Fragment key={`client-${index}`}>
                                    <Typography variant="caption" sx={{ color: "#65676b" }}></Typography>
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
                                        <Avatar sx={{ width: 24, height: 24 }}>
                                        </Avatar>
                                        <Typography style={{ backgroundColor: "#E3E6EA", borderRadius: 10, padding: "6px 10px 6px 10px" }} variant="body1">{item.message}</Typography>
                                    </Stack>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })
                    : null
                }
                <div ref={props.endRef}></div>
            </Stack>
        </React.Fragment>
    );
}
